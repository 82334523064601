/**!
 * WACE ATAR Calculator
 * By Daniel15 - 2011-2019
 * Feel free to use any of this, but please link back to my site (dan.cx)
 */

import '../../css/main.css';

import React from 'react';
import ReactDOM from 'react-dom';

import {initAnalytics} from '../analyticsLogger';
import CalculatorWACE from '../components/CalculatorWACE';
import CalculatorContainer from '../containers/CalculatorContainer';
import Data from '../../data/wace.json';
import {State} from '../constants';

ReactDOM.render(
  <CalculatorContainer
    appConfig={{
      ...Data,
      alertMessage: (
        <>
          NOTE: TISC no longer provide a scaling report, so you must enter your
          estimated <strong>scaled</strong> scores in order to estimate your
          ATAR.
        </>
      ),
      bugReportMessage: (
        <p id="bugs">
          Please report any errors or bugs by emailing{' '}
          <strong>
            <a href="mailto:%63%61l&#99;%40at%61r%6e%6ftes%2e&#99;om">
              calc@atarnotes.com
            </a>
          </strong>
          .
        </p>
      ),
      resourcesURL: null,
      showSuggestedCourses: false,
      state: State.WACE,
    }}
    calculator={CalculatorWACE}
  />,
  document.getElementById('calculator'),
);

initAnalytics(State.WACE);
